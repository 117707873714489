html, body {
  min-height: 100%;
  height: 100%;
}

textarea {
  resize: none;
  width: 100%;
}

.md-button {
  margin: 0px !important;
  padding: 0px !important;
}

.filters-bar {
  background: white;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.15);
  padding: 0 10px;
}

.filters-bar input[type=text] {
  border: 0;
  height: 100%;
  padding: 0 10px;
  min-height: 50px;
  outline: none;
}

.filters-bar > * {
  margin: 0 5px;
}

.day {
  padding-left: 10px !important;
  margin-bottom: 20px;
}

.day .day-title {
  padding: 5px;
}

.day .day-title h4 {
  margin: 5px;
}

.day .day-meetings {
  width: 100%;
  border-top: solid 1px #dddddd;
}

.day .day-meetings md-list {
  padding: 5px;
}

.day .day-meetings .meeting-details {
  margin: 0px;
  padding: 0px;
}

.create-meeting {
  position: absolute;
  bottom: 5px;
  right: 15px;
}

.select-search {
  padding: 15px 17px;
  outline: none;
  border: 0;
  border-bottom: solid 1px #eee;
}

.top-menu .md-button {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

project-selector {
  background: #e6e6e6 !important;
}

md-menu-content {
  padding: 0;
}

md-toolbar input {
  color: white !important;
  font-size: 16px !important;
}

.title-input {
  font-size: 18px;
  background: transparent;
  border: 0;
  border-bottom: solid 2px transparent;
  outline: none;
}

.title-input:hover {
  border-bottom: solid 2px #ccc;
}

.title-input:focus {
  border-bottom: solid 2px #222;
}

.editable-wrap {
  width: 100% !important;
}

.meeting-details {
  margin: 10px;
}

.meeting-project {
  font-size: 16px !important;
  background-color: transparent !important;
  color: white !important;
}

.file-preview {
  min-width: 160px;
  height: 30px;
  overflow: hidden;
  background-color: white;
  margin: 5px;
  line-height: 30px;
  line-break: strict;
  vertical-align: middle;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
}

.file-preview a {
  padding: 5px;
}

.file-preview a textarea {
  font-size: 12px;
  color: #666;
  text-decoration: none;
  text-align: center;
  border: 0;
  outline: none;
  overflow: hidden;
  cursor: default;
  position: relative;
  width: 100%;
  vertical-align: middle;
}

.file-preview a textarea:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: white;
  cursor: pointer;
}

.file-preview a:hover {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
}

.file-preview a:hover md-icon {
  color: white !important;
  opacity: 1 !important;
}

.file-preview a[download] md-icon {
  font-size: 20px;
}

md-list-item::before, md-list-item .md-list-item-inner::before {
  display: none;
}

.md-resize-wrapper:after {
  display: none;
}

md-input-container {
  margin-bottom: 10px;
  margin-top: 10px;
}

md-input-container .md-errors-spacer {
  display: none;
}

.md-resize-handle {
  display: none;
}

md-card md-card-content {
  padding: 5px;
}

.transition-300, .title-input, offline #offline-msg {
  transition: all 300ms ease !important;
}

.transition-1000 {
  transition: all 1s ease;
}

.text-accent {
  color: #006C94;
}

.text-warning {
  color: #ff6400;
}

.text-success {
  color: #00e600;
}

.text-danger {
  color: #ff6666;
}

.text-fade {
  opacity: 0.5;
}

.text-center {
  text-align: center;
}

.no-bg {
  background: transparent;
}

.no-margin {
  margin: 0;
}

.bg-cover {
  background-size: cover;
  background-position: center center;
}

.bg-danger {
  background: rgba(255, 0, 0, 0.1);
}

.bg-success {
  background: rgba(0, 128, 0, 0.1);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

.wrapper {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 15px;
}

@media (min-width: 960px) {
  .wrapper {
    padding: 40px;
  }
}

.full-page {
  position: relative;
  min-height: 100%;
}

.full-page .full-page-content {
  position: relative;
  z-index: 10;
}

.full-page .full-page-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
}

.full-page .full-page-background:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: ' ';
  background-color: #222;
  opacity: 0.85;
}

.avatar {
  max-width: 60px;
  max-height: 60px;
  border-radius: 600px;
}

.avatar-sm {
  max-width: 40px;
  max-height: 40px;
  border-radius: 40px;
}

.avatar-xs {
  max-width: 30px;
  max-height: 30px;
  border-radius: 30px;
}

.avatar-big {
  max-width: 100px;
  max-height: 100px;
  border-radius: 100px;
}

a, a:active, a:visited {
  color: #006C94;
  text-decoration: none;
}

.padding {
  padding: 20px;
}

#loading-bar .bar {
  background: #006C94;
}

#loading-bar-spinner .spinner-icon {
  border-top-color: #006C94 !important;
  border-left-color: #006C94 !important;
  color: #006C94 !important;
}

.text-accent {
  color: #006C94;
}

.text-warning {
  color: #ff6400;
}

.text-success {
  color: #00e600;
}

.text-danger {
  color: #ff6666;
}

.text-fade {
  opacity: 0.5;
}

.text-center {
  text-align: center;
}

.text-deprecated {
  text-decoration: line-through;
}

offline {
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

offline #offline-msg {
  background: #222;
  padding: 20px;
  margin-top: -100px;
  letter-spacing: 1px;
  font-size: 18px;
}

offline #offline-msg, offline #offline-msg md-icon {
  color: #ff9999;
}

offline #offline-msg.active {
  margin-top: 0;
}

